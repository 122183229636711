<template  origin="center center">
  <v-container id="availability" fluid  class="pt-3">

    <v-row class="pl-4 pr-7 pt-7">
            <!-- Menu -->
            <v-col cols="auto" class="pt-2">
              <div class="breadcrumb pa-1 ml-3">
                    <Breadcrumbs />
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-tooltip bottom>
                <template #activator="{ on, attrs }" >
                  <v-btn
                    color="primary"
                    small
                    text
                    icon
                    style="background-color: white; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;"
                    class="ml-1"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="modalDownload = true"
                  >
                    <v-icon class="mr-1" size="medium"> fa-solid fa-file-excel </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("views.reports.availability.table.download_excel") }}</span>
              </v-tooltip>
            </v-col>
    </v-row>

    <v-row align="center" justify="center" no-gutters>
      <v-col cols="auto" class="mr-2">
        <TableComponent
          ref="AvailabilityTable"
          :searchP="searchField"
          id="availability"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="modalDownload" width="400px" persistent>
      <ModalDownload
        @closeForm="onCloseModalDownload"
        :idMonitoringOrder="idMonitoringOrder"
        :key="index"
      />
    </v-dialog>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      modalDownload: false,
      idMonitoringOrder: 0,
      index: 0,
      valida: false,
      dialogDetails: false,
      dateDownload: [],
      menu: false,
    };
  },
  components: {
    ModalDownload: () =>
      import(
        /* webpackChunkName: "ModalDownload" */ "./modals/ModalDownloadComponent.vue"
      ),
    TableComponent: () =>
      import(
        /* webpackChunkName: "TableComponent" */ "./table/TableComponent.vue"
      ),
    Breadcrumbs: () =>
      import(/* webpackChunkName: "Breadcrumbs" */ "./breadcrumb/BreadCrumb.vue"),
  },
  name: "StatusOffers",
  computed: {
    ...mapGetters({
      user: "auth/user",
      search: "search/search",
    }),
    idCompany() {
      const userMap = JSON.parse(this.user);
      return userMap.user.idCompany;
    },
    searchField() {
      return this.search;
    },
  },
  metaInfo() {
    return {
      title: this.$t("components.layout.sidebar.status_of_offers"),
    };
  },
  watch: {
    token(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.token)
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
      }
    },
    searchField(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(() => {
          this.onRealoadTable();
        });
      }
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    onRealoadTable() {
      this.$refs.StatusOffersTable.loadData();
    },
    onCloseModalDownload() {
      this.modalDownload = false
    },
  },
};
</script>
<style scoped>
@import url('./AvailabilityStyle.scss');
</style>
